import React from "react";
import "./HomePage.css";
import Logo from "./../../logo.svg";

function HomePage() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
            <main className="flex-grow-1 d-flex justify-content-center align-items-center p-5">
                <img src={Logo} alt="Arion Corporations Logo" className="img-fluid" />
            </main>
            <footer>
                <div className="container text-center">
                    <div className="row">
                        <div className="col-12">
                        <small>
                            <p>&copy; 2016 - {currentYear} Arion Corporations. All rights reserved.</p>
                        </small>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

       
    );
    }

export default HomePage;

// Path: src/Pages/HomePage/HomePage.css